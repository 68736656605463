import React from 'react'
import { graphql } from 'gatsby'
import { HelmetDatoCms } from 'gatsby-source-datocms'

const ImprintPage = ({ data: { imprint } }) => (
    <article className="sheet">
        <HelmetDatoCms seo={imprint.seoMetaTags} />
        <div className="sheet__inner">
            <h1 className="sheet__title">{imprint.title}</h1>
            <div
                className="sheet__body"
                dangerouslySetInnerHTML={{
                    __html: imprint.content,
                }}
            />
        </div>
    </article>
)

export default ImprintPage

export const query = graphql`
  query ImprintQuery {
    imprint: datoCmsContentPage(title: {eq: "Impressum"}) {
        title
        content
        seoMetaTags {
            ...GatsbyDatoCmsSeoMetaTags
        }
    }
  }
`
